<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="p-5 text-center bg-gret-blueBlack rounded-lg">
      <div class="text-2xl text-white font-semibold leading-7 tracking-wider">
        Pago Confirmado
      </div>
      <div class="flex justify-center text-2xl text-center text-white font-semibold tracking-wider mb-7">
        <gret-svg icon="success" class="mt-7" />
      </div>
      <div class="text-base text-white font-semibold leading-5 tracking-wider p-5">
        Tu plan ya se encuentra activo.
      </div>
      <hr />
      <div class="text-xl text-white font-semibold leading-7 tracking-wider p-5">
        ¡Estamos publicando tu carpeta!
      </div>
    </div>
  </modal>
</template>
  
<script>
  import Modal from './modal.vue';
  import GretSvg from './gret-svg.vue';
  
  export default {
    name: 'ModalNewUserSuccess',
    components: {
      Modal,
      GretSvg
    },
    data() {
      return {
        open: true,
        loading: false,
      };
    },
    methods: {
      onClose() {
        this.open = false;
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>
  .add-button-item {
    @apply bg-gret-smallHub p-5 rounded-lg shadow-md items-center text-white font-semibold text-lg flex flex-col hover:bg-gret;
  }

  .icon-button-svg {
    @apply bg-gret-blueBlack rounded-full p-3
  }

  .icon-button-svg-hover {
    @apply bg-blue-700 rounded-full p-3
  }
</style>
    