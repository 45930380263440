<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="p-5 text-center bg-gret-blueBlack rounded-lg">
      <div class="flex justify-center text-2xl text-center text-white font-semibold tracking-wider mb-7">
        <gret-svg icon="success" class="mt-7" />
      </div>
      <div class="text-xl font-semibold leading-7 tracking-wider text-white">
        ¡Genial!
      </div>
      <div class="text-3xl font-semibold leading-9 tracking-wider p-5 text-white">
        Es hora de agregar el <br/> primer ítem a tu carpeta
      </div>
      <div class="flex justify-center">
        <a
          @click="confirm"
        >
          <button class="flex items-center justify-center px-4 rounded-md w-full h-12 text-white bg-gret hover:bg-gret text-base font-bold px-8 py-3.5 w-full mb-4 gret-button md:w-60 md:mb-0">
            <div class="flex items-center justify-center">
              <div>Agregar ítem</div>
            </div>
          </button>
        </a>
      </div>
    </div>
  </modal>
</template>
  
<script>
  import Modal from './modal.vue';
  import GretSvg from './gret-svg.vue';
  
  export default {
    name: 'ModalNewUserSuccess',
    components: {
      Modal,
      GretSvg
    },
    props: {
      response: { type: String, default: null },
    },
    data() {
      return {
        open: true,
        loading: false,
      };
    },
    methods: {
      onClose() {
        this.open = false;
        this.$emit('close');
      },
      confirm() {
        this.$emit('redirectCreator');
      },
    },
  };
</script>

<style scoped>
  .add-button-item {
    @apply bg-gret-smallHub p-5 rounded-lg shadow-md items-center text-white font-semibold text-lg flex flex-col hover:bg-gret;
  }

  .icon-button-svg {
    @apply bg-gret-blueBlack rounded-full p-3
  }

  .icon-button-svg-hover {
    @apply bg-blue-700 rounded-full p-3
  }
</style>
  