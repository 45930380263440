<template>
  <div class="w-full mt-3">
    <div class="flex flex-row space-x-1 ">
      <label
        class="text-white"
        :class="{ 'font-bold' : !communityPrivate }"
      >
        Publicar carpeta
      </label>
      <toggle-button
        id="changePrivacy"
        class="mb-4"
        v-model="communityPrivate"
      />

      <!---<label
        class="text-white"
        :class="{ 'font-bold': communityPrivate }"
      >
        {{ $t('message.communities.privacy.privateCommunity') }}
      </label>-->
    </div>
    <change-privacy-modal
      v-if="showModal"
      :new-private="communityPrivate"
      :community-id="communityId"
      @close="closePrivacyModal"
      @success="privacyChanged"
    />
    <!--<community-info-banner
      v-if="showPrivacyChangedMessage"
      :main-text="$tc('message.communities.privacy.successMainMessage')"
      :secondary-text="secondaryMessage()"
      @close="closeInfoBanner()"
      can-close
    />-->
  </div>
</template>
<script>
import ToggleButton from '../components/shared/toggle-btn';
import ChangePrivacyModal from '../components/change-privacy-modal';

export default {
  components: { ToggleButton, ChangePrivacyModal },
  props: {
    originalPrivate: { type: Boolean, defualt: false },
    communityId: { type: Number, required: true },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    privacyChanged() {
      this.showPrivacyChangedMessage = true;
      this.toggleModal();
    },
    closeInfoBanner() {
      this.showPrivacyChangedMessage = false;
    },
    closePrivacyModal() {
      this.communityPrivate = !this.communityPrivate;
    },
    privacyText(privacy) {
      return privacy ?
        this.$t('message.communities.privacy.privateCommunity') :
        this.$t('message.communities.privacy.publicCommunity');
    },
    secondaryMessage() {
      const message = this.$t('message.communities.privacy.successSecondaryMessage',
        { privacy: this.privacyText(this.communityPrivate) });

      return this.communityPrivate ?
        message + this.$t('message.communities.privacy.successSecondaryMessageDisclaimer') :
        message;
    },
  },
  computed: {
  },
  watch: {
    communityPrivate() {
      this.toggleModal();
    },
  },
  data() {
    return ({
      communityPrivate: this.originalPrivate,
      errors: null,
      loading: false,
      showModal: false,
      showPrivacyChangedMessage: false,
    });
  },
};
</script>
