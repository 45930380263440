<template>
  <div>
    <button
      class="mr-3 inline-flex items-center justify-center p-2.5 text-xs font-medium tracking-wide text-white uppercase border-2 rounded cursor-pointer border-gret-grayButton bg-gret-grayButton hover:opacity-90 hover:border-gray-500 w-48 mb-2 sm:mb-0"
      @click="openModal">
      Cancelar suscripción
    </button>

    <cancel-delete-modal
      v-if="showModal"
      title="¿Estás seguro de cancelar la suscripción?"
      message="Ten en cuenta que ya no podrás publicar tu carpeta"
      @cancel="cancelSubscription"
      @close="hideModal"
    />
  </div>
</template>

<script>
  import CancelDeleteModal from './cancel-delete-modal.vue';
  import api from '../api/index';

  export default {
    name: 'CancelSubs',
    components: { CancelDeleteModal },
    props: {
      communityId: { type: String, default: null },
    },
    data() {
      return {
        showModal: false,
      };
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      hideModal() {
        this.showModal = false;
      },
      async cancelSubscription() {
        api({
          method: 'PATCH',
          url: `/api/v1/communities/${this.communityId}/cancel`,
          data: {
            community: {
            },
          },
        }).then(() => {
            location.reload();
        }).catch((error) => {
            console.table(error);
        });
      },
    },
  };
</script>
