<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="p-8 pt-16 text-center bg-gret-blueBlack rounded-lg">
      <div class="text-2xl font-semibold text-center leading-10 text-white">
        {{ title }}
      </div>
      <div class="text-xl font-base mt-4 leading-7 text-center text-white">
        {{ message }}
      </div>
      <div class="grid grid-cols-2 gap-2 ">
        <a
          class="mt-6 inline-flex items-center justify-center py-3 font-medium tracking-wide text-white uppercase border-2 rounded-md cursor-pointer border-gret-smallHub bg-gret-smallHub"
          @click="onClose"
        >
          Cancelar
        </a>
        <a
          class="mt-6 inline-flex items-center justify-center py-3 font-medium tracking-wide text-white uppercase border-2 rounded-md cursor-pointer border-gret bg-gret"
          :class="loading && 'opacity-50'"
          @click="confirm"
        >
          {{ type == 'delete' ? 'Eliminar carpeta' : 'Confirmar' }}
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from './modal.vue';

  export default {
    name: 'CancelDeleteModal',
    components: {
      Modal,
    },
    props: {
      title: { type: String, default: null },
      message: { type: String, default: null },
      type: { type: String, default: null },
    },
    data() {
      return {
        open: true,
        loading: false,
      };
    },
    methods: {
      onClose() {
        this.open = false;
        this.$emit('close');
      },
      confirm() {
        this.loading = true;
        this.$emit('cancel');
      },
    },
  };
</script>
  