<template>
  <div class="w-full">
    <div class="flex flex-row space-x-1 ">
      <label
        class="text-white font-semibold"
        v-if="communityPrivate == 'true'"
      >
        Despublicar carpeta
      </label>
      <label
        v-else
        class="text-white font-semibold"
      >
        Publicar carpeta
      </label>
      <div class="relative flex items-center justify-center w-16 h-6">
        <toggle-button
          @change="onChangeEventHandler"
          :value="planPublic"
          color="#2679ff"
          :key="componentKey"
        />
      </div>
    </div>
    <payment-modal
      v-if="(showModal === true && planActive === false)"
      :community-id="communityId"
      @close="closePrivacyModal"
      @success="modalSuccess"
      @error="modalFailed"
    />
    <payment-modal-success
      v-if="showModalSuccess"
      :community-id="communityId"
    />
    <payment-modal-failed
      v-if="showModalFailed"
      :community-id="communityId"
    />
  </div>
</template>
<script>
  import { ToggleButton } from 'vue-js-toggle-button';
  import PaymentModal from '../components/payment-modal';
  import PaymentModalSuccess from '../components/payment-modal-success';
  import PaymentModalFailed from '../components/payment-modal-failed';
  import communitiesApi from '../api/community';

  export default {
    name: 'PaymentToggle',
    components: { PaymentModal, PaymentModalSuccess, PaymentModalFailed, ToggleButton },
    props: {
      originalPrivate: { type: String, default: null },
      communityId: { type: Number, required: true },
      communityPlanActive: { type: String, default: '' },
    },
    methods: {
      onChangeEventHandler() {
        this.toggleModal();
        if (this.communityPlanActive === 'true') {
          communitiesApi.update(
            this.communityId,
            { public: this.planPublic },
          ).then(() => {
            console.log('public actualizado');
            location.reload();
          }).catch((error) => {
              console.table(error);
          });
        }
      },
      modalSuccess() {
        this.showModal = false;
        this.showModalSuccess = true;
        setTimeout(() => { 
          this.showModalSuccess = false; 
          location.reload();
        }, 4000);
      },
      modalFailed() {
        this.showModal = false;
        this.showModalFailed = true;
        setTimeout(() => { 
          this.showModalSuccess = false; 
          location.reload();
        }, 4000);
      },
      toggleModal() {
        this.showModal = !this.showModal;
        this.planPublic = !this.planPublic;
      },
      closePrivacyModal() {
        this.componentKey = this.componentKey * 13;
        this.planPublic = !this.planPublic;
        this.showModal = false;
      },
    },
    data() {
      return ({
        communityPrivate: this.originalPrivate,
        errors: null,
        loading: false,
        showModal: false,
        showModalSuccess: false,
        showModalFailed: false,
        planActive: this.communityPlanActive === 'true' ? true : false,
        planPublic: this.originalPrivate === 'true' ? true : false,
        componentKey: this.communityId,
      });
    },
  };
</script>
