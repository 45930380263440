<template>
  <div>
    <form
      ref="form"
      :action="path"
      method="post"
      id="new_user"
      class="new_user"
      accept-charset="UTF-8"
      @submit.prevent="getFormValues"
    >
      <csrf-input />
      <div class="mt-3">
        <input type="hidden" id="user_role" name="user[role]" :value="params == 'creator' ? 'creator' : 'consumer'" />
        <input type="hidden" id="user_redir" name="user[redir]" :value="redir" />
        <div 
          v-if="params == 'creator'"
          class="mb-6"  
        >
          <div class="text-white mb-1">
            Nombre de la carpeta <label class="text-red-700">*</label>
          </div>
          <input
            class="gret-input-black"
            placeholder="Ej: Curso de matemáticas"
            name="user[first_combo_name]"
            id="user_first_combo_name"
            type="text"
          />
        </div>
        <div class="mb-6">
          <div class="text-white mb-1">
            Tus nombres y apellidos <label class="text-red-700">*</label>
          </div>
          <input
            class="gret-input-black"
            placeholder="Nombre Apellido"
            name="user[name]"
            id="user_name"
            type="text"
          />
        </div>
        <div class="mb-6">
          <div class="text-white mb-1">
            Correo electrónico <label class="text-red-700">*</label>
          </div>
          <input
            class="gret-input-black"
            placeholder="nombre@mail.com"
            name="user[email]"
            id="user_email"
            type="email"
          />
        </div>
        <div class="mb-6">
          <div class="text-white mb-1">Contraseña <label class="text-red-700">*</label></div>
          <toggleable-password-input
            autocomplete="new-password"
            placeholder="contraseña"
            name="user[password]"
            id="user_password"
          />
        </div>
        <div class="flex mb-2">
          <div class="mr-2">
            <input type="checkbox" v-model='terms' id="terms" name="cb" />
          </div>
          <div class="text-white mr-1">
            Acepto los
          </div>
          <a
            href="https://www.gret.io/terminos-condiciones"
            target="_blank"
            rel="noopener noreferrer"
            class="flex text-gret hover:underline"
          >
            Términos y Condiciones de Gret
          </a>
        </div>
        <div class="mb-4 text-gray-400 text-xs">
          <label class="text-red-700 mr-1">*</label>Campos obligatorios
        </div>
        <div class="flex flex-col items-center">
          <button 
            class="w-full mb-4 md:w-60 md:mb-0 inline-flex items-center justify-center px-6 py-3 font-medium tracking-wide text-white uppercase rounded-md"
            :class="isDisabled ? 'cursor-not-allowed bg-gray-500' : 'bg-gret hover:bg-gret cursor-pointer'"
            :disabled="isDisabled"
            type="submit"
          >
            <div class="flex items-center justify-center">
              {{ params == 'creator' ? 'Continuar' : 'Registrar' }}
            </div>
          </button>
        </div>
      </div>
    </form>
    <modal-new-user-success
      v-if="showModal"
      @close="hideModal"
      @redirectCreator="toCombo"
      :combo-name="response"
    />
  </div>  
  </template>

<script>
  import Modal from './modal.vue';
  import ModalNewUserSuccess from '../components/modal-new-user-success.vue';
  import ToggleablePasswordInput from './toggleable-password-input.vue';

  export default {
    name: 'ModalNewUser',
    components: {
      Modal,
      ModalNewUserSuccess,
      ToggleablePasswordInput
    },
    props: {
      path: {
        type: String,
        required: true,
      },
      params: {
        type: String,
        default: '',
      },
      redir: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        open: true,
        loading: false,
        showModal: false,
        actionButton: true,
        terms: false,
        slug: '',
      };
    },
    methods: {
      modalOpen() {
        this.showModal = true;
      },
      hideModal() {
        this.showModal = false;
      },
      toCombo() {
        window.location.href = `/communities/first-combo/manage`;
      },
      async getFormValues(SubmitEvent) {
        if (this.params == 'creator'){
          if((SubmitEvent.target.elements.user_name.value !== '' && SubmitEvent.target.elements.user_name.value !== undefined) &&
            (SubmitEvent.target.elements.user_first_combo_name.value !== '' && SubmitEvent.target.elements.user_first_combo_name.value !== undefined) &&
            (SubmitEvent.target.elements.user_email.value !== '' && SubmitEvent.target.elements.user_email.value !== undefined) &&
            (SubmitEvent.target.elements.user_password.value !== '' && SubmitEvent.target.elements.user_password.value !== undefined)
          ){
            this.slug = SubmitEvent.target.elements.user_first_combo_name.value;
            this.$refs.form.submit();
            this.terms = false;
            setTimeout(() => { this.showModal = true; }, 3000);
            
          } else{
            this.$refs.form.submit();
          }
        } else{
          console.log('user_redir')
          console.table(SubmitEvent.target.elements.user_redir);
          if((SubmitEvent.target.elements.user_name.value !== "" && SubmitEvent.target.elements.user_name.value !== undefined) &&
            (SubmitEvent.target.elements.user_email.value !== "" && SubmitEvent.target.elements.user_email.value !== undefined) &&
            (SubmitEvent.target.elements.user_password.value !== "" && SubmitEvent.target.elements.user_password.value !== undefined)
          ){
            this.$refs.form.submit();
          } else{
            this.$refs.form.submit();
          }
        }
      },
    },
    computed: {
      isDisabled: function(){
        return !this.terms;
      }
    }
  };
</script>
