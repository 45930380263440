<template>
  <div class="p-8 rounded-lg shadow-lg bg-gret-gray hover:shadow-white hover:gret-gray-light">
    <template v-if="currentStatus && currentStatus !== 'canceled' && !changePlan">
      <div class="text-lg font-medium text-gray-200 hover:text-white">
        {{ currentTierOption.label }}
      </div>
      <div class="text-gret-text-gray ">
        {{ currentTierOption.explanation }}
      </div>
      <div class="flex mt-2 text-gret-text-gray">
        {{ currentStatusLabel }}
        <template v-if="pendingOrInactive">
          <div class="ml-1">
            ·
          </div>
          <div class="ml-1">
            Estamos esperando la confirmación del pago
          </div>
        </template>
      </div>
      <div
        v-if="pendingOrInactive"
        class="mt-6"
      >
        <a
          :href="communityPlansPathWithCurrentTier"
          data-method="post"
          class="w-full mb-4 gret-button small md:w-auto md:mb-0 md:mr-6"
        >
          Pagar plan
        </a>
      </div>
      <div
        v-else
        class="mb-6"
      >
        <button
          class="w-full mb-4 gret-button small md:w-auto md:mb-0 md:mr-2"
          @click="toggleChangePlan"
        >
          Cambiar Plan
        </button>
        <a
          :href="communityPlanPath"
          data-method="delete"
          data-confirm="¿Estas seguro que deseas cancelar el plan?"
          class="w-full mb-4 gret-button gret-button--red small md:w-auto md:mb-0"
        >
          Cancelar Plan
        </a>
      </div>
    </template>
    <template v-else>
      <div
        v-if="changePlan"
        class="mb-4 text-lg text-white"
      >
        Plan de la carpeta actual: <span class="font-bold">{{ currentTierOption.label }}</span>
      </div>
      <form
        :action="communityPlansPath"
        method="post"
      >
        <csrf-input />
        <div class="mb-6">
          <label
            for="community_plan_tier"
            class="mb-1 gret-dark-input-label"
          >
            {{ changePlan ? 'Nuevo plan de la carpeta' : 'Plan de la carpeta' }}
          </label>
          <explained-select
            name="tier"
            :options="tierOptions"
            dark
          />
        </div>
        <div class="mb-6">
          <input
            type="submit"
            value="Contratar plan"
            name="commit"
            class="w-full mb-4 gret-button small md:w-auto md:mb-0 md:mr-2"
          >
          <button
            v-if="changePlan"
            @click="toggleChangePlan"
            class="text-gret hover:underline"
          >
            Cancelar cambio
          </button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>

export default {
  props: {
    communityPlanId: { type: Number, default: null },
    currentTier: { type: String, default: null },
    currentStatus: { type: String, default: null },
    currentStatusLabel: { type: String, default: null },
    communityPlansPath: { type: String, required: true },
    tierOptions: { type: Array, default: () => [] },
  },
  data() {
    return {
      changePlan: false,
    };
  },
  computed: {
    currentTierOption() {
      return this.tierOptions.find(option => option.value === this.currentTier);
    },
    communityPlansPathWithCurrentTier() {
      return `${this.communityPlansPath}?tier=${this.currentTier}`;
    },
    communityPlanPath() {
      return `${this.communityPlansPath}/${this.communityPlanId}`;
    },
    pendingOrInactive() {
      return ['pending', 'inactive'].includes(this.currentStatus);
    },
  },
  methods: {
    toggleChangePlan() {
      this.changePlan = !this.changePlan;
    },
  },
};
</script>
